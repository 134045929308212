import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, useRef, useState } from 'react'

import { Carousel } from '@/features/carousel'
import { mq, widthInCols } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { AwardRecipientRecent } from './AwardRecipientRecent'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: readonly (Queries.AwardRecipientRecentFragment | null)[] | null
  thumbnailStyle:
    | Queries.AwardSectionFragment['thumbnailStyle']
    | undefined
  bgColor: 'WHITE' | 'GRAY'
}

export const RecentRecipients = ({
  data,
  thumbnailStyle,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const navRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | null>(null)
  const styles = {
    section: css`
      display: grid;
    `,
    heading: css`
      grid-column: 1 / -1;
      margin: 0 var(--margin);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5em 0 0;
      h3 {
        font-size: var(--fs-36);
        margin: 0.5em 0 0.75em;
      }
      ${bgColor === 'WHITE' &&
      css`
        border-top: 2px solid ${colors.gray85};
      `}
      ${bgColor === 'GRAY' &&
      css`
        border-top: 2px solid ${colors.gray45};
        color: #fff;
      `}
    `,
    carousel: css`
      --gtr: var(--gtr-18);
      --slide-width: ${widthInCols({ count: 9 })};
      --scroll-width: calc(var(--slide-width) + var(--gtr));
      height: ${height}px;
      transition: height 750ms ease;
      ${mq().m} {
        --slide-width: ${widthInCols({ count: 10 })};
      }
      ${mq().s} {
        --slide-width: ${widthInCols({ count: 12 })};
      }
    `,
    carouselContent: css`
      display: grid;
      grid-column-gap: var(--gtr);
      grid-template-columns: repeat(
        ${data?.length},
        var(--slide-width)
      );
      padding: 0 var(--margin);
      align-items: flex-start;
    `,
    carouselNav: css`
      ${bgColor === 'GRAY' &&
      css`
        --arrow-color: ${colors.gray75};
        --arrow-color-hover: ${colors.redLight};
      `}
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <div css={styles.heading}>
        <h3>Recent Recipients</h3>
        <div
          css={styles.carouselNav}
          ref={navRef}
        />
      </div>
      <Carousel
        css={styles.carousel}
        contentCss={styles.carouselContent}
        navContainer={navRef.current}
        navVariant="ABOVE"
        snap
      >
        {[...(data || [])]
          .sort(
            (a, b) =>
              parseInt(b?.awardYear || '') -
              parseInt(a?.awardYear || '')
          )
          .map((recipient, i) => (
            <AwardRecipientRecent
              data={recipient}
              bgColor={bgColor}
              carouselHeight={height}
              setCarouselHeight={setHeight}
              thumbnailStyle={thumbnailStyle}
              key={i}
            />
          ))}
      </Carousel>
    </section>
  )
}
